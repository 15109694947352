const _formats = {
  currency: {
    style: 'currency',
  } as Intl.NumberFormatOptions,
  currencyNoDecimals: {
    style: 'currency',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  } as Intl.NumberFormatOptions,
  currencyNoDecimalsWithSign: {
    style: 'currency',
    signDisplay: 'exceptZero',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  } as Intl.NumberFormatOptions,
  currencyTwoDecimals: {
    style: 'currency',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  } as Intl.NumberFormatOptions,
  currencyFourDecimals: {
    style: 'currency',
    maximumFractionDigits: 4,
    minimumFractionDigits: 4,
  } as Intl.NumberFormatOptions,
  currencyTwoOptionalDecimals: {
    style: 'currency',
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  } as Intl.NumberFormatOptions,
  currencyTwoDecimalsWithSign: {
    style: 'currency',
    signDisplay: 'exceptZero',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  } as Intl.NumberFormatOptions,
};

export const numberFormats = {
  cs: {
    'currency-CZK': {
      currency: 'CZK',
      ..._formats.currency,
    } as Intl.NumberFormatOptions,
    'currencyNoDecimals-CZK': {
      currency: 'CZK',
      ..._formats.currencyNoDecimals,
    } as Intl.NumberFormatOptions,
    'currencyNoDecimalsWithSign-CZK': {
      currency: 'CZK',
      ..._formats.currencyNoDecimalsWithSign,
    },
    'currencyTwoDecimals-CZK': {
      currency: 'CZK',
      ..._formats.currencyTwoDecimals,
    } as Intl.NumberFormatOptions,
    'currencyFourDecimals-CZK': {
      currency: 'CZK',
      ..._formats.currencyFourDecimals,
    } as Intl.NumberFormatOptions,
    'currencyTwoOptionalDecimals-CZK': {
      currency: 'CZK',
      ..._formats.currencyTwoOptionalDecimals,
    } as Intl.NumberFormatOptions,
    'currencyTwoDecimalsWithSign-CZK': {
      currency: 'CZK',
      ..._formats.currencyTwoDecimalsWithSign,
    } as Intl.NumberFormatOptions,
    'currency-EUR': {
      currency: 'EUR',
      ..._formats.currency,
    } as Intl.NumberFormatOptions,
    'currencyNoDecimals-EUR': {
      currency: 'EUR',
      ..._formats.currencyNoDecimals,
    } as Intl.NumberFormatOptions,
    'currencyNoDecimalsWithSign-EUR': {
      currency: 'EUR',
      ..._formats.currencyNoDecimalsWithSign,
    },
    'currencyTwoDecimals-EUR': {
      currency: 'EUR',
      ..._formats.currencyTwoDecimals,
    } as Intl.NumberFormatOptions,
    'currencyFourDecimals-EUR': {
      currency: 'EUR',
      ..._formats.currencyFourDecimals,
    } as Intl.NumberFormatOptions,
    'currencyTwoOptionalDecimals-EUR': {
      currency: 'EUR',
      ..._formats.currencyTwoOptionalDecimals,
    } as Intl.NumberFormatOptions,
    'currencyTwoDecimalsWithSign-EUR': {
      currency: 'EUR',
      ..._formats.currencyTwoDecimalsWithSign,
    } as Intl.NumberFormatOptions,
    noDecimals: {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    } as Intl.NumberFormatOptions,
    twoDecimals: {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    } as Intl.NumberFormatOptions,
    percent: {
      style: 'percent',
    } as Intl.NumberFormatOptions,
    percentWithSign: {
      style: 'percent',
      signDisplay: 'always',
    } as Intl.NumberFormatOptions,
    percentTwoDecimals: {
      style: 'percent',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    } as Intl.NumberFormatOptions,
    percentTwoDecimalsWithSign: {
      style: 'percent',
      signDisplay: 'exceptZero',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    } as Intl.NumberFormatOptions,
  },
  // TODO(martin_obadal): this is a cs copy, configure to use english standards
  en: {
    'currency-CZK': {
      currency: 'CZK',
      ..._formats.currency,
    } as Intl.NumberFormatOptions,
    'currencyNoDecimals-CZK': {
      currency: 'CZK',
      ..._formats.currencyNoDecimals,
    } as Intl.NumberFormatOptions,
    'currencyNoDecimalsWithSign-CZK': {
      currency: 'CZK',
      ..._formats.currencyNoDecimalsWithSign,
    },
    'currencyTwoDecimals-CZK': {
      currency: 'CZK',
      ..._formats.currencyTwoDecimals,
    } as Intl.NumberFormatOptions,
    'currencyFourDecimals-CZK': {
      currency: 'CZK',
      ..._formats.currencyFourDecimals,
    } as Intl.NumberFormatOptions,
    'currencyTwoOptionalDecimals-CZK': {
      currency: 'CZK',
      ..._formats.currencyTwoOptionalDecimals,
    } as Intl.NumberFormatOptions,
    'currencyTwoDecimalsWithSign-CZK': {
      currency: 'CZK',
      ..._formats.currencyTwoDecimalsWithSign,
    } as Intl.NumberFormatOptions,
    noDecimals: {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    } as Intl.NumberFormatOptions,
    twoDecimals: {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    } as Intl.NumberFormatOptions,
    percent: {
      style: 'percent',
    } as Intl.NumberFormatOptions,
    percentWithSign: {
      style: 'percent',
      signDisplay: 'always',
    } as Intl.NumberFormatOptions,
    percentTwoDecimals: {
      style: 'percent',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    } as Intl.NumberFormatOptions,
    percentTwoDecimalsWithSign: {
      style: 'percent',
      signDisplay: 'exceptZero',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    } as Intl.NumberFormatOptions,
  },
};

const _czechResolver = function (choice: number) {
  if (choice === 1) {
    return 0;
  }

  if (choice >= 2 && choice <= 4) {
    return 1;
  }

  return 2;
};

const pluralizationRules = {
  // logic taken from here https://github.com/i18next/i18next/blob/master/src/PluralResolver.js
  cs: _czechResolver,
};

export default defineI18nConfig(() => ({
  numberFormats,
  pluralRules: pluralizationRules,
  fallbackLocale: 'cs',
  availableLocales: ['cs', 'en'],
}));
